.rdw-image-alignment-options-popup {
  position: absolute;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  width: auto !important;
  cursor: pointer;
  z-index: 100;
}
.rdw-alignment-option-left {
  justify-content: flex-start;
}
.rdw-image-alignment {
  position: relative;
}
.rdw-image-imagewrapper {
  position: relative;
}
.rdw-image-center {
  text-align: center;
}
.rdw-image-left {
  text-align: left;
}
.rdw-image-right {
  text-align: right;
}
.rdw-image-alignment-options-popup-right {
  right: 0;
}
.rdw-image-alignment-options-popup-center {
  left: 50%;
  transform: translate(-50%, 0);
}
